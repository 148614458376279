<template>
<div class="popup">
  <van-popup v-model:show="show"  closeable :close-on-click-overlay="false" @close="close">
    <div class="password">
      <div class="title">{{$t('home.mm')}}</div>
      <van-cell-group inset>
        <van-field v-model="password"  center :placeholder="$t('home.srmm')" maxlength="6" type="password"/>
      </van-cell-group>
      <div class="confirm-btn">
        <van-button class="confirm" @click="submit">{{$t('home.qd')}}</van-button>
      </div>
    </div>
  </van-popup>
</div>
</template>
<script setup>
import {ref, defineProps, defineEmits, getCurrentInstance} from 'vue'
import {setPassword} from "../../api";
import {Toast} from "vant";
import {getPublicKey} from "../../utils/decimalTool";
const { $t } = getCurrentInstance().proxy;
// 设置密码
// eslint-disable-next-line no-unused-vars
const props = defineProps(["showPassword"])

const emit = defineEmits(['changePassword'])
const show=ref(props.showPassword)
const password=ref('')
const close=()=>{
  show.value=false
  emit('changePassword',1)
  //1关闭 2成功
}
const submit=()=>{

  setPassword(
      {
        "password":getPublicKey(password.value)
      }
  ).then((res)=>{
    console.log(res,111)
    if(res.code==='200'){
      Toast( $t('home.success'))
      show.value=false
      emit('changePassword',2)
      //1关闭 2成功
    }else if(res.code==='311'){
      sessionStorage.removeItem('token')
      sessionStorage.removeItem('address')
      sessionStorage.removeItem('userId')
      emit('changePassword',1)
    }else{
      Toast(res.msg)

    }
  }).catch(err=>{
    console.log(err,222)
  })

}
</script>
<style scoped lang="less">
/deep/.van-popup {
  background: #232A2E;
  border-radius: 25px;
}
/deep/.van-popup__close-icon--top-right{
  top:30px;
  height: 24px;
}
.password{
  box-sizing: border-box;
  width: 312px;
//height: 320px;
  background: #232A2E;
  border-radius: 25px;
  padding: 28px 19px 31px 19px;
.title{
  font-weight: bold;
  font-size: 18px;
  color: #FFFFFF;
  margin-bottom: 30px;
}
.confirm-btn{
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
.confirm{
  width: 187px;
  height: 56px;
  background: linear-gradient(90deg, #57F6CD, #AF65EE);
  box-shadow: 0px 5px 14px 0px rgba(181,186,255,0.3);
  border-radius: 28px;
  font-weight: bold;
  font-size: 17px;
  color: #000000;
  text-align: center;
  line-height: 56px;
  border: none;
}
}
/deep/ .van-cell-group{
  width: 100%;
  height: 62px;
  background: #000000;
  border-radius: 31px;
}
/deep/ .van-cell-group--inset{
  margin: 0;
.van-cell{
  font-weight: 500;
  font-size: 17px;
  color: #7E8D95;
  width: 264px;
  height: 62px;
  background: #000000;
  border-radius: 31px;
}
.van-field__control{
  text-align: center;
  font-weight: bold;
  font-size: 21px;
  color: #FFFFFF;
}
}
}
/deep/.van-field__control::placeholder{
  font-weight: 500;
  font-size: 17px;
  color: #7E8D95;
}
</style>
